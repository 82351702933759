// alert("!Clients");
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
global.Swiper = Swiper;
document.querySelectorAll('.clients__items.swiper-container').forEach(function (slider) {
  var sliderInstance = new Swiper(slider, {
    speed: 400,
    spaceBetween: 0,
    slidesPerView: 1,
    watchOverflow: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next'
    },
    breakpoints: {
      1440: {
        slidesPerView: 3
      },
      769: {
        slidesPerView: 2
      }
    }
  });
});