// import with changed language
import Pristine from '../../js/vendors/pristine.js';
Pristine.addValidator("contact", function (value) {
  return /^\d{11}$/.test(value) || /^\+\d{11}$/.test(value) || /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
}, "Пожалуйста, введите адрес электронной почты или телефон.");
document.querySelectorAll("textarea.field").forEach(function (field) {
  var wrapper = document.createElement('div');
  wrapper.classList.add('textarea__wrap');
  field.parentNode.insertBefore(wrapper, field);
  wrapper.appendChild(field);
});
document.querySelectorAll("label.field--file").forEach(function (field) {
  var input = field.querySelector('input'),
      span = field.querySelector('span');
  input.addEventListener('change', function (evt) {
    if (input.value) {
      var filename = input.value.substring(input.value.indexOf('\\') >= 0 ? input.value.lastIndexOf('\\') : input.value.lastIndexOf('/'));

      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }

      span.innerText = filename;
    }
  });
});
document.querySelectorAll("form.js-validate").forEach(function (form) {
  var pristine = new Pristine(form, {
    // class of the parent element where the error/success class is added
    classTo: 'field__container',
    errorClass: 'error',
    successClass: 'success',
    // class of the parent element where error text element is appended
    errorTextParent: 'field__container',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'field__container-msg'
  });
  form.addEventListener('submit', function (evt) {
    evt.preventDefault();

    if (pristine.validate()) {
      grecaptcha.execute();
    }
  });
});

window.submitReCaptcha = function (token) {
  var data = new FormData(document.getElementById('form'));
  data.append('ajax', 1);
  var request = new XMLHttpRequest();

  request.onreadystatechange = function () {
    var response = JSON.parse(request.responseText);
    var form_wrapper = document.getElementById('ready');
    form_wrapper.classList.remove('msg-success');
    form_wrapper.classList.remove('msg-error');
    form_wrapper.classList.add('msg-' + response.result);
  };

  request.open(form.method, 'ajax.php');
  request.send(data);
};